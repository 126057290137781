import React from 'react';

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'bottom',
		},
		title: {
			display: true,
			text: 'Panola County Bookings Statistics',
		},
	},
};

const DoughnutChart = ({ stats }) => {
	const data = {
		labels: ['24 Hours', '7 Days', '30 Days'],
		datasets: [
			{
				label: 'Bookings Totals',
				data: [
					parseInt(stats.bookings24hours),
					parseInt(stats.bookings7days),
					parseInt(stats.bookings30days),
				],
				backgroundColor: ['#224A80', '#D7E39B', '#C36525'],
				borderColor: ['#224A80', '#D7E39B', '#C36525'],
				borderWidth: 1,
			},
		],
	};

	return (
		<div>
			<Doughnut options={options} data={data} />
		</div>
	);
};

export default DoughnutChart;
