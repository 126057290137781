import React from 'react';

import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';

const Sort = ({ handleAllClick, handleRecentClick, handleLast24 }) => {
	return (
		<div>
			<ButtonGroup>
				<Button variant='secondary' onClick={handleAllClick}>
					All
				</Button>
				<Button variant='secondary' onClick={handleRecentClick}>
					Recent
				</Button>
				<Button variant='secondary' onClick={handleLast24}>
					Last 24 Hr
				</Button>
			</ButtonGroup>
		</div>
	);
};

export default Sort;
