import React from 'react';
import { NavLink } from 'react-router-dom';

import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import './Header.css';
const title = process.env.REACT_APP_FACILITY_TITLE;

const Header = () => {
	return (
		<div>
			<Navbar
				style={{ marginBottom: '300px', paddingRight: '15px' }}
				bg='dark'
				variant='dark'
				expand='lg'
				fixed='top'
				collapseOnSelect>
				<Navbar.Brand>{title}</Navbar.Brand>
				<Navbar.Toggle aria-controls='basic-navbar-nav' />
				<Navbar.Collapse id='basic-navbar-nav'>
					<Nav className='mr-auto'>
						<Nav.Link>
							<NavLink
								eventKey='1'
								style={{
									color: '#ced4da',
									textDecoration: 'none',
								}}
								to='/'>
								Inmate Roster
							</NavLink>
						</Nav.Link>
						<Nav.Link>
							<NavLink
								eventKey='2'
								style={{
									color: '#ced4da',
									textDecoration: 'none',
								}}
								to='/statistics'>
								Statistics
							</NavLink>
						</Nav.Link>
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
};

export default Header;
