import React from 'react';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend
);

export const options = {
	responsive: true,
	plugins: {
		legend: {
			position: 'top',
		},
		title: {
			display: true,
			text: 'Panola County Detention Center',
		},
	},
};

const labels = ['Total', 'Male', 'Female', 'Other'];

const BarChart = ({ stats }) => {
	const data = {
		labels,
		datasets: [
			{
				label: 'Current Inmate Statistics',
				data: [
					parseInt(stats.totalmales) +
						parseInt(stats.totalfemales) +
						parseInt(stats.totalother),
					parseInt(stats.totalmales),
					parseInt(stats.totalfemales),
					parseInt(stats.totalother),
				],
				backgroundColor: '#2A4E6E',
			},
		],
	};

	return (
		<>
			<Bar options={options} data={data} />
		</>
	);
};

export default BarChart;
