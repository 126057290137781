import React, { useEffect, useState } from 'react';

import Card from 'react-bootstrap/Card';
import BarChart from '../components/charts/BarChart';
import DoughnutChart from '../components/charts/DoughnutChart';

import { fetchStats } from '../utils/ApiCalls';

import './Demographics.css';

const Demographics = () => {
	const [stats, setStats] = useState({});

	useEffect(() => {
		getStats();
	}, []);

	const getStats = async () => {
		let stats = await fetchStats();
		setStats(stats.data);
	};

	return (
		<div className='demographics-main'>
			<Card body className='stat-card'>
				<BarChart stats={stats} />
			</Card>
			<Card body className='stat-card'>
				<DoughnutChart stats={stats} />
			</Card>
		</div>
	);
};

export default Demographics;
