import React from 'react';

import Offcanvas from 'react-bootstrap/Offcanvas';
import Image from 'react-bootstrap/Image';
import ListGroup from 'react-bootstrap/ListGroup';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import mugshot from '../resources/ina.jpg';

const Info = ({ show, handleOffCanvas, info }) => {
	const charges = info.charges;
	const bonds = info.bonds;

	return (
		<>
			<Offcanvas
				show={Object.entries(info).length > 0 && show}
				onHide={handleOffCanvas}>
				<Offcanvas.Header>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Offcanvas.Title className=''>
							{`${info.lastName}, ${info.firstName} ${info.middleName}`}
						</Offcanvas.Title>

						<div
							style={{
								position: 'absolute',
								right: '1rem',
								top: '0.5rem',
							}}
							onClick={handleOffCanvas}>
							<FontAwesomeIcon
								icon={faXmark}
								className='fa-xl text-end'
							/>
						</div>
					</div>
				</Offcanvas.Header>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<Image
						src={
							info.image
								? `data:image/png;base64,${info.image}`
								: mugshot
						}
						rounded={true}
						id={info.bookNo}
						style={{
							width: '10rem',
							height: '10rem',
						}}
					/>
				</div>
				<Offcanvas.Body>
					<ListGroup variant='flush'>
						<ListGroup.Item>
							Booking Number: {info.bookNo}
						</ListGroup.Item>
						<ListGroup.Item>
							Permanent ID: {info.permId}
						</ListGroup.Item>
						<ListGroup.Item>
							Booking Date: {info.bookDate}
						</ListGroup.Item>
						<ListGroup.Item>
							Booking Time: {info.bookTime}
						</ListGroup.Item>
						<ListGroup.Item>
							Date of Birth: {info.birthDate}
						</ListGroup.Item>
						<ListGroup.Item>
							Arrest Date: {info.arrestDate}
						</ListGroup.Item>
						<ListGroup.Item>
							Arrest Officer: {info.arrestOfficer}
						</ListGroup.Item>
						<Card style={{ marginTop: '0.5rem' }}>
							<Card.Header>Charges</Card.Header>
							<Card.Body style={{ padding: '0px' }}>
								<Table responsive striped bordered size='sm'>
									<thead>
										<tr>
											<th>Code</th>
											<th>Description</th>
										</tr>
									</thead>
									<tbody>
										{charges &&
											charges.map((c) => {
												return (
													<tr key={c.chargeId}>
														<td>{c.code}</td>
														<td>{c.description}</td>
													</tr>
												);
											})}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
						{/* Bonds */}
						{bonds && bonds.length > 0 && (
							<Card style={{ marginTop: '0.5rem' }}>
								<Card.Header>Bonds</Card.Header>
								<Card.Body style={{ padding: '0px' }}>
									<Table
										responsive
										striped
										bordered
										size='sm'>
										<thead>
											<tr>
												<th>Type</th>
												<th>Amount</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
											{bonds &&
												bonds.map((b) => {
													return (
														<tr key={b.bondId}>
															<td>
																{b.bondType}
															</td>
															<td>
																{b.bondAmount}
															</td>
															<td>
																{b.bondStatus}
															</td>
														</tr>
													);
												})}
										</tbody>
									</Table>
								</Card.Body>
							</Card>
						)}
					</ListGroup>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	);
};

export default Info;
