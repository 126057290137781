import React, { useEffect, useState } from 'react';
import Bangedup from '../components/Bangedup';
import Info from '../components/Info';
import Sort from '../components/Sort';
import Row from 'react-bootstrap/Row';
import ReactLoading from 'react-loading';
import {
	fetchCaptives,
	fetchCaptiveInfo,
	fetchCaptiveMugshot,
} from '../utils/ApiCalls';
import { isLessThan24HourAgo } from '../utils/Dates';
import { get, set } from 'idb-keyval';
import './Current.css';

const Current = () => {
	const [captives, setCaptives] = useState([]);
	const [captivesShown, setCaptivesShown] = useState([]);
	const [info, setInfo] = useState({});
	const [show, setShow] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getCaptives();
	}, []);

	const getCaptives = async () => {
		let captivesArr = await fetchCaptives();
		setCaptives(captivesArr);
		const sortedCaptives = sortAlphabetically(captivesArr);
		//await getCaptiveImages(sortedCaptives); After changing view to send images
		setCaptivesShown(sortedCaptives);
		setLoading(false);
	};

	const sortAlphabetically = (captivesArr) => {
		try {
			const sortedCaptives = captivesArr.sort((a, b) => {
				var nameA = a.lastName;
				var nameB = b.lastName;
				if (nameA < nameB) {
					return -1; //nameA comes first
				}
				if (nameA > nameB) {
					return 1; // nameB comes first
				}
				return 0; // names must be equal
			});
			return sortedCaptives;
		} catch (error) {
			removeAndReload();
		}
	};

	const sortRecent = async (captivesArr) => {
		try {
			const sortedCaptives = captivesArr.sort((a, b) => {
				var nameA = new Date(a.bookDate);
				var nameB = new Date(b.bookDate);
				if (nameA > nameB) {
					return -1;
				}
				if (nameA < nameB) {
					return 1;
				}
				return 0;
			});
			return sortedCaptives;
		} catch (error) {
			removeAndReload();
		}
	};

	const sortLast24 = async (captivesArr) => {
		try {
			let less24Arr = [];
			captivesArr.forEach((c) => {
				const d = `${c.bookDate} ${c.bookTime}`;
				isLessThan24HourAgo(new Date(d)) && less24Arr.push(c);
			});
			const sortedCaptives = sortAlphabetically(less24Arr);
			return sortedCaptives;
		} catch (error) {
			removeAndReload();
		}
	};

	const getCaptiveImages = async (sortedCaptives) => {
		const newField = 'image';
		let newValue;
		let newStorArr = [];
		let storImages;

		await get('mugshots')
			.then((val) => {
				storImages = val || [];
			})
			.catch((err) => console.log(err));

		for await (const [captive] of sortedCaptives.entries()) {
			const index = storImages.findIndex(
				(k) => k.bookNo === captive.bookNo
			);

			if (index === -1) {
				let image = [];
				image = await fetchCaptiveMugshot(captive.bookNo);
				if (image.success) {
					if ('data' in image && image.data.length > 0) {
						newValue = image.data[0].imageData;
						captive[newField] = newValue;
						newStorArr.push({
							bookNo: captive.bookNo,
							data: newValue,
						});
					} else {
						captive[newField] = '';
					}
				}
			} else {
				captive[newField] = storImages[index].data;
				newStorArr.push({
					bookNo: storImages[index].bookNo,
					data: storImages[index].data,
				});
			}
		}
		setCaptives(sortedCaptives);
		set('mugshots', newStorArr).then(() => setLoading(false));
	};

	const getCaptiveInfo = async (id) => {
		let captiveInfo = await fetchCaptiveInfo(id);
		const index = captives.findIndex((k) => k.bookNo === id);
		const newField = 'image';
		let newValue = captives[index].image;
		captiveInfo[newField] = newValue;
		setInfo(captiveInfo);
	};

	const removeAndReload = async () => {
		indexedDB.deleteDatabase('mugshots');
		await getCaptives();
	};

	const handleOnClick = (e) => {
		getCaptiveInfo(e.target.id);
		handleOffCanvas();
	};

	const handleAllClick = async () => {
		const caps = [...captives];
		const sortedCaptives = await sortAlphabetically(caps);
		setCaptivesShown(sortedCaptives);
	};

	const handleRecentClick = async (e) => {
		e.preventDefault();
		const caps = [...captives];
		const sortedCaptives = await sortRecent(caps);
		setCaptivesShown(sortedCaptives);
	};

	const handleLast24 = async () => {
		const caps = [...captives];
		const sortedCaptives = await sortLast24(caps);
		setCaptivesShown(sortedCaptives);
	};

	const handleOffCanvas = () => {
		setShow(!show);
		show === false && setInfo({});
	};

	return (
		<>
			<div className='bangedup-main'>
				{loading ? (
					<div>
						<h2>Fetching Info</h2>
						<ReactLoading
							style={{ marginLeft: '10%' }}
							type='bubbles'
							color={'#0B5586'}
							height={'8%'}
							width={'8%'}
						/>
					</div>
				) : (
					<>
						<Row style={{ width: '100vw' }}>
							<div className='sort'>
								<Sort
									handleAllClick={handleAllClick}
									handleRecentClick={handleRecentClick}
									handleLast24={handleLast24}
								/>
							</div>
						</Row>
						{captivesShown &&
							captivesShown.map((captive) => {
								return (
									<Bangedup
										key={captive.bookNo}
										captive={captive}
										handleOnClick={handleOnClick}
									/>
								);
							})}
					</>
				)}
			</div>
			<div>
				<Info
					show={show}
					handleOffCanvas={handleOffCanvas}
					info={info}
				/>
			</div>
		</>
	);
};

export default Current;
