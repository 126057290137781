import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import Current from './pages/Current';
import Demographics from './pages/Demographics';

const App = () => {
	return (
		<div className='App'>
			<Router>
				<Header />
				<Routes>
					<Route index element={<Current />} />
					<Route path='statistics' element={<Demographics />} />
				</Routes>
			</Router>
		</div>
	);
};

export default App;
