import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';

import mugshot from '../resources/ina.jpg';

import './Bangedup.css';

const Bangedup = ({ captive, handleOnClick }) => {
	return (
		<>
			<div className='bangedup-container'>
				<div>
					<Card
						className='w-card'
						id={captive.bookNo}
						defaultValue={captive}>
						<Card.Body onClick={(e) => handleOnClick(e)}>
							<Row style={{ display: 'flex' }}>
								<Col className='w-1-col' id={captive.bookNo}>
									<Image
										src={
											!captive.hasOwnProperty('image') ||
											captive.image.length === 0
												? mugshot
												: `data:image/png;base64,${captive.image}`
										}
										rounded={true}
										id={captive.bookNo}
										style={{
											width: '8rem',
											height: '8rem',
										}}
									/>
								</Col>
								<Col
									xs={7}
									className='w-3-col'
									id={captive.bookNo}>
									<Row id={captive.bookNo}>
										<Card.Title id={captive.bookNo}>
											{`${captive.lastName}, ${captive.firstName} ${captive.middleName}`}
										</Card.Title>
									</Row>
									<Row id={captive.bookNo}>
										<Card.Text
											id={captive.bookNo}
											className='text-muted mb-0'>
											Details:
										</Card.Text>
										<Card.Text
											id={captive.bookNo}
											className='mb-0'
											style={{ fontSize: '.8rem' }}>
											Booking Number: {captive.bookNo}
										</Card.Text>
										<Card.Text
											id={captive.bookNo}
											className='mb-0'
											style={{ fontSize: '.8rem' }}>
											Permanent ID: {captive.permId}
										</Card.Text>
										<Card.Text
											id={captive.bookNo}
											style={{ fontSize: '.8rem' }}>
											Booking Date: {captive.bookDate}
										</Card.Text>
									</Row>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</div>
			</div>
		</>
	);
};

export default Bangedup;
