const axios = require('axios').default;

const apiUrl = 'https://panolams.bluhorse.net';

export const fetchCaptives = async () => {
	const url = `${apiUrl}/api/getjail/current`;
	let data;
	try {
		data = await axios.get(url);
	} catch (error) {
		return error;
	}

	return data.data.data;
};

export const fetchCaptiveInfo = async (id) => {
	const url = `${apiUrl}/api/getinmate/byid/${id}`;
	let data;
	try {
		data = await axios.get(url);
	} catch (error) {
		return error;
	}

	delete data.data.data['ssn'];

	return data.data.data;
};

export const fetchCaptiveMugshot = async (id) => {
	const url = `${apiUrl}/api/getinmate/mugshotbyid/${id}`;
	let data;
	try {
		data = await axios.get(url);
	} catch (error) {
		return error;
	}
	return data.data;
};

export const fetchStats = async () => {
	const url = `${apiUrl}/api/getjail/current/stats`;
	let data;
	try {
		data = await axios.get(url);
	} catch (error) {
		return error;
	}

	return data.data;
};
